import { CircularProgress } from '@material-ui/core';
import { Box } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import LanguageSettingsIcon from '@material-ui/icons/Language';
import React, { useState } from 'react';
import styled, { css, keyframes } from 'styled-components';

import {
    DashboardSection,
    ENGLISH_LANGUAGE,
    MEETING_LANGAUGES,
    MEETING_LANGUAGE_SEPARATOR,
    OUTPUT_LANGUAGES,
} from '@spinach-shared/constants';
import { ClientEventType } from '@spinach-shared/types';

import { getUser, patchAllSeriesSettings, patchUser } from '../../../../apis';
import { ReactComponent as SpinachAIVideoImage } from '../../../../assets/spinach-video-display.svg';
import { ReactComponent as VideoCam } from '../../../../assets/videocam.svg';
import { GlobalModal } from '../../../../atoms';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useGlobalModal,
    useIsCustomBrandingEnabled,
    useLanguageSelectionInDashboardEnablement,
    useOutputLanguageSelectionEnablement,
    useOutputLanguageSelectionPerMeetingEnablement,
    useProFeatures,
    useWindowSize,
} from '../../../../hooks';
import {
    useGlobalBrandedImageUri,
    useGlobalCombinedSpinachVideoBackgroundImageUri,
    useGlobalSpinachVideoBackgroundImageUri,
} from '../../../../hooks/useGlobalBrandedImage';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, BodySmallOnboard, HeaderThree, lightTheme } from '../../../../styles';
import { SetValue } from '../../../../types';
import { ClientLogger } from '../../../../utils';
import { BootstrapTooltip, Column, DropDown, ProBadge, Row, Spacing } from '../../../common';
import { SpinachSwitch } from '../../../common/SpinachSwitch';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import SecondaryButton from '../../../stand-up/SecondaryButton';
import { Label } from './common';

function EditSummaries(): JSX.Element {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const hasProFeatures = useProFeatures();

    const [isLoadingEdit, setIsLoadingEdit] = useState(false);

    return (
        <BodySmallOnboard>
            <Row>
                {isLoadingEdit ? (
                    <span
                        style={{
                            display: 'flex',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={'16px'}
                            thickness={6}
                            style={{
                                color: lightTheme.primary.greenLight,
                            }}
                        />
                    </span>
                ) : (
                    <span style={{ width: '40px' }} />
                )}
                <Column>
                    <Row vCenter>
                        <BootstrapTooltip
                            title={user.isForcedIntoEditFlow ? 'Edit Summaries has been turned on for you.' : ''}
                            placement="top"
                        >
                            <Box>
                                <SpinachSwitch
                                    checked={
                                        user.isForcedIntoEditFlow ||
                                        (user.metadata.isEditingAiEmailsByDefault && user.hasProFeatures) ||
                                        (isLoadingEdit && !user.metadata.isEditingAiEmailsByDefault)
                                    }
                                    disabled={isLoadingEdit || !hasProFeatures || user.isForcedIntoEditFlow}
                                    onChange={async () => {
                                        if (user.isForcedIntoEditFlow) {
                                            return;
                                        }

                                        setIsLoadingEdit(true);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Toggle Enable Edit Summary (User Settings)',
                                            ToggleTo: !!user.metadata.isEditingAiEmailsByDefault ? 'Off' : 'On',
                                        });

                                        const newValue = !user.metadata.isEditingAiEmailsByDefault;

                                        try {
                                            await Promise.all([
                                                patchAllSeriesSettings({
                                                    isHostEditing: newValue,
                                                }),
                                                patchUser({
                                                    metadata: {
                                                        isEditingAiEmailsByDefault: newValue,
                                                    },
                                                }),
                                            ]);

                                            const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                            if (updatedUser.user) {
                                                setUser(updatedUser.user);
                                            }

                                            setToastText(
                                                'This has been applied to meetings that you have invited Spinach to.'
                                            );
                                        } catch (error: any) {
                                            ClientLogger.error('Failed to change edit settings', {
                                                spinachUserId: user.spinachUserId,
                                                isHostEditing: newValue,
                                                errorMessage: error.message,
                                            });

                                            setToastText('Something went wrong when updating your settings.');
                                        }
                                        setIsLoadingEdit(false);
                                    }}
                                />
                            </Box>
                        </BootstrapTooltip>
                        <BodyRegularOnboard
                            style={{ marginLeft: '15px', marginTop: '2px', opacity: hasProFeatures ? 1 : 0.5 }}
                        >
                            Edit summaries before they publish
                        </BodyRegularOnboard>
                    </Row>
                </Column>
            </Row>
        </BodySmallOnboard>
    );
}

const DisplayTranscriptSectionOption = () => {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const [isLoading, setIsLoading] = useState(false);
    const shouldShowTranscriptSectionOptions =
        user.isToggleMeetingTranscriptsEnabled && !user.hideMeetingTranscriptsOverride;
    const hasProFeatures = useProFeatures();

    if (!shouldShowTranscriptSectionOptions) {
        return <></>;
    }
    return (
        <BodySmallOnboard>
            <Row>
                {isLoading ? (
                    <span
                        style={{
                            display: 'flex',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={'16px'}
                            thickness={6}
                            style={{
                                color: lightTheme.primary.greenLight,
                            }}
                        />
                    </span>
                ) : (
                    <span style={{ width: '40px' }} />
                )}
                <Column>
                    <Row vCenter>
                        <SpinachSwitch
                            style={{ opacity: hasProFeatures ? 1 : 0.5 }}
                            checked={
                                !user.shouldHideMeetingTranscripts || (isLoading && user.shouldHideMeetingTranscripts)
                            }
                            disabled={isLoading || !hasProFeatures}
                            onChange={async () => {
                                setIsLoading(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Toggle Enable Meeting Transcripts (User Settings)',
                                    ToggleTo: !!user.shouldHideMeetingTranscripts ? 'Off' : 'On',
                                });

                                await Promise.all([
                                    patchUser({
                                        metadata: {
                                            shouldHideMeetingTranscripts: !user.shouldHideMeetingTranscripts,
                                        },
                                    }),
                                ]);

                                const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                if (updatedUser.user) {
                                    setUser(updatedUser.user);
                                }

                                setToastText('This has been applied to meetings that you have invited Spinach to.');

                                setIsLoading(false);
                            }}
                        />
                        <BodyRegularOnboard
                            style={{ marginLeft: '15px', marginTop: '2px', opacity: hasProFeatures ? 1 : 0.5 }}
                        >
                            Display transcripts for my meeting
                        </BodyRegularOnboard>
                    </Row>
                </Column>
            </Row>
        </BodySmallOnboard>
    );
};

const DisplayVideoPlaybackSectionOption = () => {
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setToastText } = useGlobalAiDashboard();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    const [isLoading, setIsLoading] = useState(false);
    const shouldShowVideoPlaybackSectionOptions = user.isToggleMeetingVideosEnabled && !user.hideMeetingVideosOverride;
    const hasProFeatures = useProFeatures();

    if (!shouldShowVideoPlaybackSectionOptions) {
        return <></>;
    }

    return (
        <BodySmallOnboard>
            <Row>
                {isLoading ? (
                    <span
                        style={{
                            display: 'flex',
                            width: '40px',
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <CircularProgress
                            size={'16px'}
                            thickness={6}
                            style={{
                                color: lightTheme.primary.greenLight,
                            }}
                        />
                    </span>
                ) : (
                    <span style={{ width: '40px' }} />
                )}
                <Column>
                    <Row vCenter>
                        <SpinachSwitch
                            style={{ opacity: hasProFeatures ? 1 : 0.5 }}
                            checked={!user.shouldHideMeetingVideos || (isLoading && user.shouldHideMeetingVideos)}
                            disabled={isLoading || !hasProFeatures}
                            onChange={async () => {
                                setIsLoading(true);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Toggle Enable Meeting Video (User Settings)',
                                    ToggleTo: !!user.shouldHideMeetingVideos ? 'Off' : 'On',
                                });

                                await Promise.all([
                                    patchUser({
                                        metadata: {
                                            shouldHideMeetingVideos: !user.shouldHideMeetingVideos,
                                        },
                                    }),
                                ]);

                                const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                if (updatedUser.user) {
                                    setUser(updatedUser.user);
                                }

                                setToastText('This has been applied to meetings that you have invited Spinach to.');

                                setIsLoading(false);
                            }}
                        />
                        <BodyRegularOnboard
                            style={{ marginLeft: '15px', marginTop: '2px', opacity: hasProFeatures ? 1 : 0.5 }}
                        >
                            Display video playback for my meetings
                        </BodyRegularOnboard>
                    </Row>
                </Column>
            </Row>
        </BodySmallOnboard>
    );
};

function OutputSettings(): JSX.Element {
    return (
        <Column style={{}}>
            <Row vCenter>
                <EditIcon htmlColor="#3E3E48" style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'unset' }}>Output</Label>
                <ProBadge />
            </Row>
            <Spacing factor={1 / 4} />
            <BodyRegularOnboard style={{ marginLeft: '40px' }}>Make Changes to the summary</BodyRegularOnboard>
            <Spacing factor={1 / 4} />
            <EditSummaries />
            <Spacing factor={1 / 4} />
            <DisplayVideoPlaybackSectionOption />
            <Spacing factor={1 / 4} />
            <DisplayTranscriptSectionOption />
        </Column>
    );
}

type ImageProps = {
    preview: boolean;
    scale: number;
};

const grow = ({ scale }: ImageProps) =>
    keyframes` 
        0% {
            transform: scale(1);
        }
        100% {
            transform: scale(${scale});
        }
`;

const shrink = ({ scale }: ImageProps) =>
    keyframes` 
        0% {
            transform: scale(${scale});
        }
        100% {
            transform: scale(1);
        }
`;

const fade = () =>
    keyframes` 
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
`;

const growAnimation = (props: ImageProps) => css`
    animation: ${grow(props)};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const shrinkAnimation = (props: ImageProps) => css`
    animation: ${shrink(props)};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const fadeAnimation = () => css`
    animation: ${fade()};
    animation-duration: 1s;
    animation-fill-mode: forwards;
`;

const ImagePreview = styled(Box)<{ width: number; height: number; startClosing: boolean }>`
    width: ${({ width }) => width};
    height: ${({ height }) => height};
    display: flex;
    position: absolute;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    background-color: #ffffffcc;
    z-index: 1;
    ${(props) => (props.startClosing ? fadeAnimation() : {})}
`;

const PreviewBox = styled.div`
    position: absolute;
    width: 142px;
    height: 80px;
    background-color: #ffffffcc;
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    z-index: 1;
`;

const ImageSelector = ({
    setPreview,
    setStartClosing,
    disabled,
}: {
    setPreview: SetValue<boolean>;
    setStartClosing: SetValue<boolean>;
    disabled: boolean;
}) => {
    const track = useExperienceTracking();
    const [isHovering, setIsHovering] = useState(false);
    const [, setGlobalModal] = useGlobalModal();
    const [combinedBrandedVideoBackgroundImageUri] = useGlobalCombinedSpinachVideoBackgroundImageUri();
    const [spinachVideoBackgroundImageUri] = useGlobalSpinachVideoBackgroundImageUri();
    const [brandedImageUri] = useGlobalBrandedImageUri();

    const isLoading = Boolean(
        spinachVideoBackgroundImageUri && brandedImageUri && !combinedBrandedVideoBackgroundImageUri
    );

    const onClick = () => {
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Open Update Brand Image Modal',
        });
        setGlobalModal(GlobalModal.ImageUpload);
    };
    const onMouseLeave = () => {
        if (disabled) {
            return;
        }
        setIsHovering(false);
    };
    const onMouseEnter = () => {
        if (disabled) {
            return;
        }
        setIsHovering(true);
    };

    return (
        <Box
            style={{
                display: 'flex',
                position: 'relative',
                alignItems: 'center',
                backgroundColor: '#F4F6F6',
                padding: '5px',
            }}
        >
            {isLoading ? (
                <CircularProgress
                    size={'20px'}
                    style={{ marginRight: '5px', marginBottom: '-5px', color: lightTheme.primary.orangeDark }}
                />
            ) : combinedBrandedVideoBackgroundImageUri ? (
                <img
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Branded Image Preview',
                            HasBrandedImage: true,
                        });
                        setPreview(true);
                        window.onmousedown = () => {
                            setStartClosing(true);
                            setTimeout(() => {
                                setPreview(false);
                                setStartClosing(false);
                                window.onmousedown = null;
                            }, 800);
                        };
                    }}
                    style={{ width: '142px', height: '80px', opacity: disabled ? 0.5 : 1 }}
                    src={combinedBrandedVideoBackgroundImageUri}
                />
            ) : (
                <SpinachAIVideoImage
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                    onClick={() => {
                        if (disabled) {
                            return;
                        }
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Branded Image Preview',
                            HasBrandedImage: false,
                        });
                        setPreview(true);
                        window.onmousedown = () => {
                            setStartClosing(true);
                            setTimeout(() => {
                                setPreview(false);
                                setStartClosing(false);
                                window.onmousedown = null;
                            }, 800);
                        };
                    }}
                    style={{ width: '142px', height: '80px', opacity: disabled ? 0.5 : 1 }}
                />
            )}
            {isHovering ? (
                <PreviewBox onMouseEnter={(e) => e.preventDefault()}>
                    <BodyRegularOnboard style={{ color: lightTheme.primary.greenLight }}>Preview</BodyRegularOnboard>
                </PreviewBox>
            ) : (
                <></>
            )}
            <OutlinedButton
                disabled={isLoading || disabled}
                style={{ margin: '8px' }}
                onClick={onClick}
                title={'Update image'}
            />
        </Box>
    );
};

const AnimatedImage = styled.img<ImageProps>`
    width: 142px;
    height: 80px;
    transform: ${(props) => `scale(${props.scale})`};
`;

const AnimatedSvg = styled(SpinachAIVideoImage)<ImageProps>`
    width: 142px;
    height: 80px;
    ${(props) => (props.preview ? growAnimation(props) : shrinkAnimation(props))}
`;

function InMeetingCustomization(): JSX.Element {
    const hasProFeatures = useProFeatures();
    const { width, height } = useWindowSize();
    const [preview, setPreview] = useState(false);
    const [startClosing, setStartClosing] = useState(false);
    const [combinedBrandedVideoBackgroundImageUri] = useGlobalCombinedSpinachVideoBackgroundImageUri();

    const isCustomBrandingEnabled = useIsCustomBrandingEnabled();

    if (!isCustomBrandingEnabled) {
        return <></>;
    }

    return (
        <>
            {preview ? (
                <ImagePreview startClosing={startClosing} width={width} height={height}>
                    {combinedBrandedVideoBackgroundImageUri ? (
                        <AnimatedImage preview={preview} scale={4} src={combinedBrandedVideoBackgroundImageUri} />
                    ) : (
                        <AnimatedSvg preview={preview} scale={4} />
                    )}
                </ImagePreview>
            ) : (
                <></>
            )}
            <Column style={{}}>
                <Row vCenter>
                    <VideoCam style={{ fontSize: '25px', marginRight: '15px' }} />
                    <Label style={{ width: 'unset' }}>In-meeting</Label>
                    <ProBadge />
                </Row>
                <Spacing factor={1 / 4} />
                <Row>
                    <BodyRegularOnboard style={{ marginLeft: '40px' }}>
                        Customize your in-meeting note taker
                    </BodyRegularOnboard>
                </Row>
                <Spacing factor={1 / 4} />
                <BodySmallOnboard>
                    <Row>
                        <span style={{ width: '40px' }} />
                        <Column>
                            <Row vCenter>
                                <ImageSelector
                                    setStartClosing={setStartClosing}
                                    setPreview={setPreview}
                                    disabled={!hasProFeatures}
                                />
                            </Row>
                        </Column>
                    </Row>
                </BodySmallOnboard>
            </Column>
        </>
    );
}

export function LanguageSettings() {
    const languages = MEETING_LANGAUGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));
    const outputLanguages = OUTPUT_LANGUAGES.map((lang) => ({
        label: lang,
        code: lang,
        disabled: MEETING_LANGUAGE_SEPARATOR === lang,
    }));
    const track = useExperienceTracking();
    const [user, setUser] = useGlobalAuthedUser();
    const { setActiveSection, setToastText } = useGlobalAiDashboard();
    const outputLanguageEnabled = useOutputLanguageSelectionEnablement();
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingOutputLanguage, setIsLoadingOutputLanguage] = useState(false);
    const isOutputLanguagePerMeetingEnabled = useOutputLanguageSelectionPerMeetingEnablement();
    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });
    return (
        <Column style={{}}>
            <Row vCenter>
                <LanguageSettingsIcon htmlColor="#3E3E48" style={{ fontSize: '25px', marginRight: '15px' }} />
                <Label style={{ width: 'unset' }}>Language</Label>
            </Row>
            <Row vCenter>
                <BodyRegularOnboard style={{ marginLeft: '40px', marginRight: '15px' }}>
                    Select the language you speak in your meetings
                </BodyRegularOnboard>
                <DropDown
                    title={'Select Meeting Language'}
                    values={languages}
                    selected={languages.find((item) => item.code === user.metadata.defaultMeetingLanguage)?.label}
                    handleSelection={async (code) => {
                        try {
                            setIsLoading(true);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: 'Global Meeting Language',
                                Language: code,
                            });

                            await patchUser({
                                metadata: {
                                    defaultMeetingLanguage: code,
                                },
                            });

                            const updatedUser = await getUser();

                            if (updatedUser.user) {
                                setUser(updatedUser.user);
                            }
                        } catch (e) {
                            ClientLogger.error('Error updating user language', e);
                            setToastText('Something went wrong when updating your meeting language.');
                        } finally {
                            setIsLoading(false);
                        }
                    }}
                    buttonProps={{
                        style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                        disabled: isLoading,
                        isLoading: isLoading,
                    }}
                />
            </Row>
            {outputLanguageEnabled ? (
                <>
                    <Row vCenter>
                        <BodyRegularOnboard style={{ marginLeft: '40px', marginRight: '15px' }}>
                            Select the language for your summary output
                        </BodyRegularOnboard>
                        <DropDown
                            title={'Select Output Language'}
                            values={outputLanguages}
                            defaultValue={outputLanguages.find((item) => item.code === ENGLISH_LANGUAGE)?.label}
                            selected={
                                outputLanguages.find((item) => item.code === user.metadata.defaultOutputLanguage)?.label
                            }
                            handleSelection={async (code) => {
                                try {
                                    setIsLoadingOutputLanguage(true);
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Global Summary Language',
                                        Language: code,
                                    });
                                    await Promise.all([
                                        patchAllSeriesSettings({
                                            outputLanguage: code,
                                        }),
                                        patchUser({
                                            metadata: {
                                                defaultOutputLanguage: code,
                                            },
                                        }),
                                    ]);

                                    const [updatedUser] = await Promise.all([getUser(), fetch()]);

                                    if (updatedUser.user) {
                                        setUser(updatedUser.user);
                                    }

                                    setToastText('This has been applied to meetings that you have invited Spinach to.');
                                } catch (e) {
                                    ClientLogger.error('Error updating summary language', e);
                                    setToastText('Something went wrong when updating your summary language.');
                                } finally {
                                    setIsLoadingOutputLanguage(false);
                                }
                            }}
                            buttonProps={{
                                style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                                disabled: isLoadingOutputLanguage,
                                isLoading: isLoadingOutputLanguage,
                            }}
                        />
                    </Row>
                    {isOutputLanguagePerMeetingEnabled ? (
                        <BodyRegularOnboard style={{ marginLeft: '40px' }}>
                            <i>
                                You can change this for individual series on the{' '}
                                <SecondaryButton
                                    title="Meetings"
                                    labelStyles={{ fontStyle: 'italic', fontWeight: 400 }}
                                    color={lightTheme.primary.midnight}
                                    onClick={() => {
                                        setActiveSection(DashboardSection.Meetings);
                                        track(ClientEventType.AIDashboardClick, {
                                            ClickedOn: 'Go to Change Series Email Settings',
                                        });
                                    }}
                                    containerStyles={{ display: 'inline-flex' }}
                                />{' '}
                                page
                            </i>
                        </BodyRegularOnboard>
                    ) : null}
                </>
            ) : (
                <Row vCenter>
                    <div
                        style={{
                            backgroundColor: lightTheme.primary.orangeLight,
                            padding: '2px 6px',
                            color: 'white',
                            fontSize: '16px',
                            fontWeight: 'bold',
                            marginLeft: '40px',
                            marginRight: '15px',
                        }}
                    >
                        Coming soon
                    </div>
                    <BodyRegularOnboard style={{ marginRight: '15px', height: 'auto', opacity: 0.5, marginTop: '3px' }}>
                        Select the language for your summary output
                    </BodyRegularOnboard>
                    <DropDown
                        title={'Select Summary Language'}
                        values={languages}
                        defaultValue={languages.find((item) => item.code === ENGLISH_LANGUAGE)?.label}
                        buttonProps={{
                            style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                            disabled: true,
                        }}
                    />
                </Row>
            )}
        </Column>
    );
}

export function GeneralSettingsSection() {
    const languageSettingsEnabled = useLanguageSelectionInDashboardEnablement();
    return (
        <>
            <Row>
                <HeaderThree>Customization</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <OutputSettings />
            <Spacing factor={1 / 2} />
            {languageSettingsEnabled ? (
                <>
                    <LanguageSettings />
                    <Spacing factor={1 / 2} />
                </>
            ) : null}
            <InMeetingCustomization />
            <Spacing factor={1 / 2} />
        </>
    );
}
