import { useEffect, useState } from 'react';

import { ClientEventType, ScribeMetadata } from '@spinach-shared/types';
import {
    StoredSpinachSeries,
    haveSummarySectionsBeenEdited,
    isMeetingTypeEnabledForEditing,
} from '@spinach-shared/utils';

import { patchSeries, useExperienceTracking, useGlobalAuthedUser } from '../..';
import { useGlobalStoredSeriesList } from './useGlobalStoredSeriesList';

export const useToggleEditSummariesForSeries = ({
    storedSeries,
    from,
}: {
    storedSeries: StoredSpinachSeries;
    from: string;
}) => {
    const [user] = useGlobalAuthedUser();
    const { updateSeriesInList } = useGlobalStoredSeriesList();
    const track = useExperienceTracking();

    const isMeetingTypeEnabledForEdit = isMeetingTypeEnabledForEditing(storedSeries.scribeMetadata?.meetingType);
    const areSectionsEdited = haveSummarySectionsBeenEdited(
        storedSeries.scribeMetadata?.meetingType,
        storedSeries.scribeMetadata?.enabledSummarySections
    );

    const isSeriesEligibleForEditSummary =
        isMeetingTypeEnabledForEdit && (!areSectionsEdited || user.isEnabledForSupportEditAfterCustomizingSections);

    const switchValue =
        (!!storedSeries.isEditSummaryEnabled && user.hasProFeatures) ||
        (isSeriesEligibleForEditSummary && user.isForcedIntoEditFlow);

    const [currentSeriesEditingFlag, setCurrentSeriesEditingFlag] = useState(switchValue);

    let disabledTooltipTitle = '';
    if (!isMeetingTypeEnabledForEdit) {
        disabledTooltipTitle = 'This meeting type is not yet supported for editing.';
    } else if (areSectionsEdited && !user.isEnabledForSupportEditAfterCustomizingSections) {
        disabledTooltipTitle = 'This meeting type has been customized and is not supported for editing.';
    } else if (user.isForcedIntoEditFlow) {
        disabledTooltipTitle = 'Edit Summaries has been turned on for you.';
    } else if (!user.hasProFeatures) {
        disabledTooltipTitle = 'Edit Summaries is a Pro feature.';
    }

    useEffect(() => {
        setCurrentSeriesEditingFlag(switchValue);
    }, [switchValue]);

    return {
        currentSeriesEditingFlag: isSeriesEligibleForEditSummary ? currentSeriesEditingFlag : false,
        disabledTooltipTitle,
        isDisabled: !!disabledTooltipTitle,
        isSeriesEligibleForEditSummary,
        toggleEditSummary: async () => {
            try {
                if (user.isForcedIntoEditFlow) {
                    return;
                }

                if (!isSeriesEligibleForEditSummary) {
                    return;
                }

                setCurrentSeriesEditingFlag(!currentSeriesEditingFlag);
                const storedSeriesJSON = storedSeries.toJSON();
                /** @NOTE optimistic update */
                updateSeriesInList({
                    ...storedSeriesJSON,
                    metadata: {
                        ...storedSeriesJSON.metadata,
                        scribeMetadata: {
                            ...(storedSeriesJSON.metadata?.scribeMetadata as ScribeMetadata),
                            isHostEditing: !currentSeriesEditingFlag,
                        },
                    },
                });
                track(ClientEventType.AIDashboardClick, {
                    ClickedOn: `Toggle Enable Edit Summary (${from})`,
                    ToggleTo: currentSeriesEditingFlag ? 'Off' : 'On',
                });
                const updatedSeries = await patchSeries(storedSeries.id, {
                    metadata: {
                        scribeMetadata: {
                            isHostEditing: !currentSeriesEditingFlag,
                        },
                    },
                });

                if (updatedSeries) {
                    updateSeriesInList(updatedSeries);
                }
            } catch (e) {
                setCurrentSeriesEditingFlag(currentSeriesEditingFlag);
                updateSeriesInList(storedSeries.toJSON());
            }
        },
    };
};
