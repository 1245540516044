import { Box } from '@material-ui/core';
import { CheckCircle } from '@material-ui/icons';
import { Lock } from '@material-ui/icons';
import { useEffect, useState } from 'react';
import styled from 'styled-components';

import { MEETING_TYPE_FEATURE_INTENT_MAP } from '@spinach-shared/constants';
import {
    ClientEventType,
    CustomizableSummaryFeatureIntents,
    FeatureIntent,
    ScribeMeetingType,
    SummaryFeatureIntents,
} from '@spinach-shared/types';
import { StoredSpinachSeries, getUniques, haveSummarySectionsBeenEdited } from '@spinach-shared/utils';

import { patchSeries } from '../../../../apis';
import {
    useExperienceTracking,
    useGlobalAiDashboard,
    useGlobalAuthedUser,
    useIncludeCustomerSuccessCheckinInMeetingTypeSelection,
    useIncludeGeneralSalesMeetingMeetingTypeSelection,
    useIncludeUserResearchInterviewInMeetingTypeSelection,
} from '../../../../hooks';
import { useGlobalStoredSeriesList } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboard, lightTheme } from '../../../../styles';
import { ListItemValue } from '../../../../types';
import { Column, DropDown, Row } from '../../../common';
import { SettingsContent, SettingsSubTitle } from './CombinedMeetingDetails';

const HoveredBodyRegularOnboard = styled(BodyRegularOnboard)`
    &:hover {
        font-weight: 600;
    }
`;

const ScribeMeetingTypeLabelMap: Record<ScribeMeetingType, string> = {
    [ScribeMeetingType.BacklogGrooming]: 'Backlog Refinement',
    [ScribeMeetingType.Generic]: 'General',
    [ScribeMeetingType.SprintPlanning]: 'Planning',
    [ScribeMeetingType.Retro]: 'Retrospective',
    [ScribeMeetingType.Standup]: 'Standup',
    [ScribeMeetingType.UserResearchInterview]: 'User Research',
    [ScribeMeetingType.CustomerSuccessCheckIn]: 'Customer Success Check-In',
    [ScribeMeetingType.GeneralSalesMeeting]: 'General Sales Meeting',
};

const CustomizableCategories: Record<CustomizableSummaryFeatureIntents, string> = {
    [FeatureIntent.MeetingAgendaItems]: 'Key Topics',
    [FeatureIntent.MeetingActionItems]: 'Action Items',
    [FeatureIntent.MeetingKeyDecisions]: 'Key Decisions',
    [FeatureIntent.MeetingBlockers]: 'Blockers',
    [FeatureIntent.MeetingWhatWentWell]: 'What Went Well',
    [FeatureIntent.MeetingWhatDidNotGoWell]: 'What Did Not Go Well',
    [FeatureIntent.MeetingWhatCanBeImproved]: 'What Can Be Improved',
    [FeatureIntent.MeetingCelebrations]: 'Celebrations',
    [FeatureIntent.PerPersonUpdates]: 'Plans and Progress',
    [FeatureIntent.PreviousMeetingInsights]: 'Insights from Last Meeting',
};

export function CustomSummaryDetails({ storedSeries }: { storedSeries: StoredSpinachSeries }) {
    const track = useExperienceTracking();
    const [user] = useGlobalAuthedUser();

    const { setToastText } = useGlobalAiDashboard();
    const includeResearchMeeting = useIncludeUserResearchInterviewInMeetingTypeSelection();
    const includeCustomerSuccessCheckin = useIncludeCustomerSuccessCheckinInMeetingTypeSelection();
    const includeGeneralSalesMeeting = useIncludeGeneralSalesMeetingMeetingTypeSelection();
    let meetingTypeValues: ListItemValue<ScribeMeetingType>[] = Object.values(ScribeMeetingType).map((meetingType) => ({
        code: meetingType,
        label: ScribeMeetingTypeLabelMap[meetingType],
    }));

    // if feature flag is disabled remove it from the list of options
    if (!includeResearchMeeting) {
        const item = meetingTypeValues.find((i) => i.code === ScribeMeetingType.UserResearchInterview);
        if (item) {
            item.disabled = true;
            item.hidePreContentAvatar = true;
            item.preContent = (
                <Box
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: '#0D674A',
                        width: '24px',
                        height: '24px',
                        marginLeft: '15px',
                        marginRight: '2px',
                        borderRadius: '2px',
                    }}
                >
                    <Lock style={{ color: 'white', width: '18px', height: '18px' }} />
                </Box>
            );
        }
    }

    if (!includeCustomerSuccessCheckin) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.CustomerSuccessCheckIn);
    }

    if (!includeGeneralSalesMeeting) {
        meetingTypeValues = meetingTypeValues.filter((i) => i.code !== ScribeMeetingType.GeneralSalesMeeting);
    }

    const [selectedMeetingTemplate, setSelectedMeetingTemplate] = useState<ListItemValue<ScribeMeetingType>>(
        meetingTypeValues.find(({ code: meetingType }) => storedSeries.meetingType === meetingType) ?? {
            code: ScribeMeetingType.Generic,
            label: ScribeMeetingTypeLabelMap[ScribeMeetingType.Generic],
        }
    );

    const [enabledSections, setEnabledSections] = useState<SummaryFeatureIntents[]>(
        storedSeries.enabledSummarySections ?? MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingTemplate.code]
    );

    const [hasShownToast, setHasShownToast] = useState(
        haveSummarySectionsBeenEdited(storedSeries.meetingType, enabledSections)
    );

    useEffect(() => {
        setEnabledSections(
            selectedMeetingTemplate.code === storedSeries.meetingType
                ? enabledSections ?? MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingTemplate.code]
                : MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingTemplate.code]
        );
    }, [selectedMeetingTemplate]);

    const {
        storedSeriesListState: { storedSeriesList },
        setStoredSeriesList,
    } = useGlobalStoredSeriesList();

    const patchStoredSeriesList = (patchedStoredSeries: StoredSpinachSeries) => {
        const newStoredSeriesList = storedSeriesList.map((series) =>
            series.id === patchedStoredSeries.id ? patchedStoredSeries : series
        );
        setStoredSeriesList(newStoredSeriesList);
    };

    const [isLoading, setIsLoading] = useState(false);

    return (
        <>
            <SettingsSubTitle>{'Summary Template'}</SettingsSubTitle>
            <SettingsContent>
                <BodyRegularOnboard>
                    <Row style={{ flexWrap: 'wrap' }}>
                        <Box my="auto" mr="10px" fontSize="16px">
                            {'Use this template for this meeting: '}
                        </Box>
                        <DropDown
                            style={{
                                width: '250px',
                            }}
                            selected={selectedMeetingTemplate.label}
                            buttonProps={{
                                style: { transform: 'scale(0.8)', transformOrigin: 'center left', padding: '4px 24px' },
                                isLoading,
                            }}
                            handleSelection={async (selectedMeetingType) => {
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: 'Meeting Type Dropdown',
                                    LastKnownBotId: storedSeries.scribeMetadata?.lastKnownBotId,
                                    SeriesId: storedSeries.id,
                                    ICalUID: storedSeries.scribeMetadata?.iCalUid,
                                    SelectedMeetingType: selectedMeetingType,
                                    CurrentMeetingType: storedSeries.meetingType,
                                });

                                try {
                                    setIsLoading(true);
                                    setSelectedMeetingTemplate({
                                        code: selectedMeetingType,
                                        label: ScribeMeetingTypeLabelMap[selectedMeetingType],
                                    });
                                    const updatedSeries = await patchSeries(storedSeries.id, {
                                        metadata: {
                                            scribeMetadata: {
                                                meetingType: selectedMeetingType,
                                                enabledSummarySections:
                                                    MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingType],
                                            },
                                        },
                                    });
                                    if (updatedSeries) {
                                        patchStoredSeriesList(new StoredSpinachSeries(updatedSeries));
                                    }
                                } catch (error) {
                                    setToastText('Error updating in meeting setting');
                                } finally {
                                    setIsLoading(false);
                                }
                            }}
                            title="Meeting Template"
                            values={meetingTypeValues}
                        />
                    </Row>
                </BodyRegularOnboard>

                <Column>
                    {selectedMeetingTemplate.code === ScribeMeetingType.UserResearchInterview ? (
                        <></>
                    ) : (
                        Object.keys(CustomizableCategories)
                            .filter((category) =>
                                MEETING_TYPE_FEATURE_INTENT_MAP[selectedMeetingTemplate.code].includes(
                                    category as CustomizableSummaryFeatureIntents
                                )
                            )
                            .map((featureIntent) => (
                                <Row key={featureIntent} vCenter>
                                    <HoveredBodyRegularOnboard
                                        onClick={async () => {
                                            try {
                                                track(ClientEventType.AIDashboardClick, {
                                                    ClickedOn: 'Summary Section Checkbox',
                                                    LastKnownBotId: storedSeries.scribeMetadata?.lastKnownBotId,
                                                    SeriesId: storedSeries.id,
                                                    ICalUID: storedSeries.scribeMetadata?.iCalUid,
                                                    ToggledSection: featureIntent,
                                                    CurrentlyEnabledSections: enabledSections,
                                                    IsTogglingOn: !enabledSections.includes(
                                                        featureIntent as CustomizableSummaryFeatureIntents
                                                    ),
                                                    IsTogglingOff: enabledSections.includes(
                                                        featureIntent as CustomizableSummaryFeatureIntents
                                                    ),
                                                    CurrentMeetingType: storedSeries.meetingType,
                                                });
                                                setIsLoading(true);

                                                const updatedEnabledSections = enabledSections.includes(
                                                    featureIntent as SummaryFeatureIntents
                                                )
                                                    ? enabledSections.filter((section) => section !== featureIntent)
                                                    : getUniques([
                                                          ...enabledSections,
                                                          featureIntent as SummaryFeatureIntents,
                                                      ]);

                                                if (
                                                    !user.isEnabledForSupportEditAfterCustomizingSections &&
                                                    haveSummarySectionsBeenEdited(
                                                        storedSeries.meetingType,
                                                        updatedEnabledSections
                                                    ) &&
                                                    !hasShownToast &&
                                                    storedSeries.metadata.scribeMetadata?.isHostEditing
                                                ) {
                                                    setToastText(
                                                        'Edit summary has been disabled. This cannot be used with a custom summary template.'
                                                    );
                                                    setHasShownToast(true);
                                                }
                                                setEnabledSections(updatedEnabledSections);
                                                const patchedSeries = await patchSeries(storedSeries.id, {
                                                    metadata: {
                                                        scribeMetadata: {
                                                            enabledSummarySections: updatedEnabledSections,
                                                        },
                                                    },
                                                });

                                                if (patchedSeries) {
                                                    patchStoredSeriesList(new StoredSpinachSeries(patchedSeries));
                                                }
                                            } catch (error) {
                                                setToastText('Error updating summary template');
                                            } finally {
                                                setIsLoading(false);
                                            }
                                        }}
                                        style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
                                    >
                                        <CheckCircle
                                            style={{
                                                color: enabledSections.includes(featureIntent as SummaryFeatureIntents)
                                                    ? lightTheme.primary.greenLight
                                                    : lightTheme.neutrals.grayDark,
                                            }}
                                            htmlColor={lightTheme.neutrals.white}
                                        />

                                        {CustomizableCategories[featureIntent as CustomizableSummaryFeatureIntents]}
                                    </HoveredBodyRegularOnboard>
                                </Row>
                            ))
                    )}
                </Column>
            </SettingsContent>
        </>
    );
}
